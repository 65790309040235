<template>
  <div>
    <iq-card>
      <b-button variant="success" size="lg" @click="deleteIndex">Make Copy</b-button>
      <b-button variant="danger" size="lg" @click="createIndex">Make contacts Index</b-button>
      <div class="mt-10"  >
        <ag-grid-vue
          id="ag-grid"
          class="ag-theme-material border height-500"
          :enableCellTextSelection="true"
          :columnDefs="contactsHeaders"
          :rowData="contactsData"
          :floatingFilter="true"
          :modules="modules"
          rowSelection="single"
          :resizable="true"
          :pagination=false
          :paginationPageSize="paginationPageSize"
          style="width: 100%; height: 70vh;"
        ></ag-grid-vue>
      <!-- <b-table :items="sale.items" ></b-table> -->
      </div>
    </iq-card>
  </div>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AgGridVue } from 'ag-grid-vue'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import { core } from '@/config/pluginInit'
import { db } from '@/config/firebase'

export default {
  name: 'ClaseAlpha',
  data () {
    return {
      // Ag-Grid Data
      modules: AllCommunityModules,
      contactsData: [],
      contactsHeaders: [],
      paginationPageSize: 10,
      contactsInfo: null,
      dataToImport: {},
      oldData1: {},
      oldData2: {},
      oldData3: {},
      oldData4: {}
    }
  },
  components: {
    AgGridVue
  },
  beforeMount () {
    this.contactsHeaders = [
      { headerName: 'Nombre', field: 'name', sortable: true, filter: true, checkboxSelection: true, width: '100' },
      { headerName: 'Nit', field: 'nit', sortable: true, filter: true, checkboxSelection: true, width: '100' },
      { headerName: 'Telefono', field: 'phone', sortable: true, filter: true, checkboxSelection: true, width: '100' },
      { headerName: 'Email', field: 'email', sortable: true, filter: true, checkboxSelection: true, width: '100' }
    ]
  },
  mounted () {
    core.index()
    this.getInfo()
  },
  firestore () {
    return {
      contactsData: db.collection('campus').doc('0DBGwdHyVklslREOHl7z')
    }
  },
  methods: {
    getInfo () {
      db.collection('campus').doc('Vs2FkUx38u3W8IDuABNF')
        .onSnapshot((doc) => {
          if (doc.data() !== undefined) {
            this.contactsInfo = doc.data().contactsInfo
          }
        })
    },
    makeCopy () {
      db.collection('co-properties/AxHgiLs9ob5f7MFfNYgt/items')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(element => {
            db.collection('campus/Vs2FkUx38u3W8IDuABNF/items')
              .add(element.data())
              .then((doc) => {
                console.log('Document written with ID:', doc.id)
              })
            this.dataToImport[querySnapshot] = querySnapshot
          })
        })
    },
    async createIndex () {
      let expr = 0
      await db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              expr += 1
              var type = ''
              if (expr > 0 && expr <= 1000) {
                type = doc.data().type
                if (typeof type === 'undefined') {
                  type = 'client'
                  console.log(doc.id, type[0], ' added')
                } else {
                  console.log(doc.id, type[0])
                  if (doc.data().type[0] === undefined) {
                    type = ''
                  } else {
                    type = doc.data().type[0]
                  }
                }
                this.oldData1[doc.id] = {
                  name: doc.data().name,
                  email: doc.data().email,
                  nit: doc.data().identificationObject.number,
                  phone: doc.data().phonePrimary,
                  docId: doc.id,
                  id: doc.data().id,
                  index: 1,
                  type: type,
                  staff: {
                    conductor: false,
                    sonido: false,
                    registro: false,
                    virtual: false,
                    soporte: false,
                    acta: false
                  }
                }
                if (expr === 1000) {
                  // Object.keys(this.oldData1).forEach(function (key) {
                  //   if (this.oldData1[key] === null || this.oldData1[key] === undefined) {
                  //     this.oldData1[key] = '-'
                  //   }
                  // })
                  db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
                    .doc('contactsIndex1')
                    .set(this.oldData1)
                    .then(() => {
                      console.log('I did it')
                    }).catch((error) => {
                      console.log('error aqui', error)
                    })
                }
              }
              if (expr > 1001 && expr <= 2000) {
                type = doc.data().type
                if (typeof type === 'undefined') {
                  type = 'client'
                  console.log(doc.id, type[0], ' added')
                } else {
                  console.log(doc.id, type[0])
                  if (doc.data().type[0] === undefined) {
                    type = ''
                  } else {
                    type = doc.data().type[0]
                  }
                }
                this.oldData2[doc.id] = {
                  name: doc.data().name,
                  email: doc.data().email,
                  nit: doc.data().identificationObject.number,
                  phone: doc.data().phonePrimary,
                  docId: doc.id,
                  id: doc.data().id,
                  index: 2,
                  type: type,
                  staff: {
                    conductor: false,
                    sonido: false,
                    registro: false,
                    virtual: false,
                    soporte: false,
                    acta: false
                  }
                }
                if (expr === 2000) {
                  // Object.keys(this.oldData2).forEach(function (key) {
                  //   if (this.oldData2[key] === null || this.oldData1[key] === undefined) {
                  //     this.oldData2[key] = '-'
                  //   }
                  // })
                  db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
                    .doc('contactsIndex2')
                    .set(this.oldData2)
                    .then(() => {
                      console.log('Ops, I did it again')
                    })
                }
              }
              if (expr > 2001 && expr <= 3000) {
                type = doc.data().type
                if (typeof type === 'undefined') {
                  type = 'client'
                  console.log(doc.id, type[0], ' added')
                } else {
                  console.log(doc.id, type[0])
                  if (doc.data().type[0] === undefined) {
                    type = ''
                  } else {
                    type = doc.data().type[0]
                  }
                }
                this.oldData3[doc.id] = {
                  name: doc.data().name,
                  email: doc.data().email,
                  nit: doc.data().identificationObject.number,
                  phone: doc.data().phonePrimary,
                  docId: doc.id,
                  id: doc.data().id,
                  index: 3,
                  type: type,
                  staff: {
                    conductor: false,
                    sonido: false,
                    registro: false,
                    virtual: false,
                    soporte: false,
                    acta: false
                  }
                }
                if (expr === 3000 || expr === querySnapshot.size) {
                  // Object.keys(this.oldData1).forEach(function (key) {
                  //   if (this.oldData1[key] === null || this.oldData1[key] === undefined) {
                  //     this.oldData1[key] = '-'
                  //   }
                  // })
                  db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
                    .doc('contactsIndex3')
                    .set(this.oldData3)
                    .then(() => {
                      console.log('I did it')
                    })
                }
              }
              if (expr > 3001 && expr <= 4000) {
                type = doc.data().type
                if (typeof type === 'undefined') {
                  type = 'client'
                  console.log(doc.id, type[0], ' added')
                } else {
                  console.log(doc.id, type[0])
                  if (doc.data().type[0] === undefined) {
                    type = ''
                  } else {
                    type = doc.data().type[0]
                  }
                }
                this.oldData4[doc.id] = {
                  name: doc.data().name,
                  email: doc.data().email,
                  nit: doc.data().identificationObject.number,
                  phone: doc.data().phonePrimary,
                  docId: doc.id,
                  id: doc.data().id,
                  index: 4,
                  type: type,
                  staff: {
                    conductor: false,
                    sonido: false,
                    registro: false,
                    virtual: false,
                    soporte: false,
                    acta: false
                  }
                }
                if (expr === 4000) {
                  db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
                    .doc('contactsIndex4')
                    .set(this.oldData4)
                    .then(() => {
                      console.log('I did it')
                    })
                }
              }
            })
          } else {
            // TODO: Debo mostar una alerta informativa
            console.log('Esta vacio')
          }
          // console.log('querySnapshot', querySnapshot.size)
          // this.oldData[1] = querySnapshot
        })
      // db.collection('campus/Vs2FkUx38u3W8IDuABNF/contacts')
      //   .doc(`contactsIndex${this.contactsInfo.indexCount}`)
      //   .set(this.oldData)
      //   .then(() => {
      //     console.log('I did it')
      //   })
    },
    deleteIndex () {
      db.collection('campus/Vs2FkUx38u3W8IDuABNF/items')
        .doc('itemsIndex')
        .delete()
        .then(() => {
          console.log('Deleted')
        })
    },
    async getNulls () {
      let data = {}
      await db.collection('campus/Vs2FkUx38u3W8IDuABNF/contacts')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(element => {
            this.oldData1[element.id] = element.data()
          })
        })
      data = this.oldData1
      Object.keys(data).forEach(function (key) {
        if (!Object.prototype.hasOwnProperty.call(data[key].identificationObject, 'number')) {
          console.log(key, data[key])
        }
      })
    },
    async cast () {
      await db.collection('campus/Vs2FkUx38u3W8IDuABNF/items')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            console.log('el lenght', querySnapshot.size)
            querySnapshot.forEach(element => {
              const data = element.data()
              console.log(element.id)
              if (Object.prototype.hasOwnProperty.call(data, '0IOt7MX0catGawfcSzzt')) {
                console.log(data)
              }
            })
          }
        }).catch((error) => {
          console.log('el error es:', error)
        })
    },
    async makeItemIndex () {
      const data = {}
      await db.collection('campus/Vs2FkUx38u3W8IDuABNF/items')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(element => {
              data[element.id] = element.data()
            })
          }
        })
      db.collection('campus/Vs2FkUx38u3W8IDuABNF/items')
        .doc('itemsIndex')
        .set(data)
        .then(() => {
          console.log('I did it')
        })
    }
  }
}
</script>
